export const pg_AnswersText0 = [
    {   id: 0,
        en: "This will be interesting for you too",
        ru: "Это вам будет тоже интересно",
    },
    {   id: 1,
        en: "In this section I have collected answers to questions that I think you will probably be interested in. If the answer to your question is not fully disclosed or is not in this list at all, write it by clicking on the button «I want to ask about something else».",
        ru: "В данном разделе собрал ответы на вопросы, которые, как мне кажется, наверняка вам будут интересны. Если ответ на ваш вопрос не раскрыт полностью или вовсе отсутствует в этом списке - напишите его, нажав на кнопку «Хочу спросить о другом».",
    },
    {   id: 2,
        en: "I want to ask about something else",
        ru: "Хочу спросить о чём-то другом",
    },
]