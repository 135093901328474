export const pg_Reviews1 = [
    {   id: 0,
        en: "Feedback from employers",
        ru: "Отзывы работодателей",
    },
    {   id: 1,
        en: "This section contains reviews from my employers and some clients from the time when I was freelancing. I will note that I almost always came to projects based on someone's recommendations, which I consider a very important indicator of justified expectations. If you want to ask questions about my work to any of my employers, I will give you contacts for a conversation. You can also leave your review by scrolling down the page.",
        ru: "В данном разделе собраны отзывы моих работодателей и некоторых клиентов со времен когда занимался фрилансом. Отмечу, что почти всегда приходил в проекты по чьим то рекомендациям, что считаю очень важным показателем оправданных ожиданий. Если вы хотите задать вопросы о по моей работе кому-то из моих нанимателей, то дам вам контакты для беседы. Также вы можете оставить свой отзыв, опустившись вниз страницы.",
    },
    {   id: 2,
        en: "I would like to leave my feedback",
        ru: "Хочу оставить свой отзыв",
    },
]