import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://samborsky.duckdns.org/api/graphql', // адрес вашего GraphQL API
        headers: {
            'Content-Type': 'application/json', // обязательный заголовок
        },
    }),
    cache: new InMemoryCache(),
});

export default client;