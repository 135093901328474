export const pg_Files = [
    {   id: 0,
        en: "Documents for downloading",
        ru: "Документы для скачивания",
    },
    {   id: 1,
        en: "Download",
        ru: "Скачать",
    },
    {   id: 2,
        en: "Russian",
        ru: "Русском",
    },
    {   id: 3,
        en: "English",
        ru: "Английском",
    },
    {   id: 4,
        en: "in",
        ru: "на",
    },
]