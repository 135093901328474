import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Portfolio1} from "../context/pg_Portfolio"
import {Link, useParams} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

const PgWork = () => {
    const params = useParams()

    // GraphQL-запрос
    const GET_POSTS = gql` 
          query Post {
                posts(
                    orderBy: { createdAt: asc },
                    where: {slug: {equals: "${params.id}"}} 
                ) {
                    url
                    tags {
                      name_ru
                      name_en
                    }
                    slug
                    pic {
                      url
                    }
                    name_ru
                    name_en
                    logo {
                      url
                    }
                    logo_style
                    link
                    hours
                    glued
                    description_ru
                    description_en
                    content
                    cat_ru
                    cat_en
                }
            }
    `;
    const { loading: CaseLoading, error: CaseError, data: CaseData } = useQuery(GET_POSTS)

    // states and vars
    const {lang, exchange, isLandscape, isPad, setMenuPage, appFirstLoad, setAppFirstLoad, toTopListener, setToTopListener,} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)

    // refs
    const h1 = useRef()
    const txtWrp = useRef()

    // functions
    function groupNumber(data) {
        let tmp = data.toLocaleString('ru-RU')
        return tmp
    }

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setMenuPage('portfolio')
            if(CaseLoading){
                return
            }else{
                setPgLoaded(true)
            }
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 800
            mobStartTimeOffset = 600
        }

        // add listeners to .TOTOP
        if(!toTopListener){
            setToTopListener(true)

            // when footer
            function onEntry(entry) {
                if(document.querySelector('.totop')){
                    entry.forEach(change => {
                        if (change.isIntersecting) {
                            document.querySelector('.totop').classList.add('totop-end')
                        }else{
                            document.querySelector('.totop').classList.remove('totop-end')
                        }
                    });
                }
            }
            let observer = new IntersectionObserver(onEntry, { threshold: [0.5] });
            observer.observe(document.querySelector('.footer-com'));

            // when scroll
            document.addEventListener("scroll", function () {
                if(document.querySelector('.totop')){
                    if(window.scrollY > 200){
                        document.querySelector('.totop').style.opacity = "1"
                    }else{
                        document.querySelector('.totop').style.opacity = "0"
                    }
                }
            })
        }

        if(isLandscape){
            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.maxWidth = '100%'
                        h1.current.style.borderBottom = '1px solid #333'
                        h1.current.children[CaseData.posts[0].logo ? 2 : 1].style.color = '#777'
                        h1.current.children[CaseData.posts[0].logo ? 2 : 1].style.textShadow = 'rgba(0,0,0,.3) 0 .45vw .8vw, 0 -1px rgba(254,254,254,.6)'
                    }
                }
                setTimeout(function () {
                    if(txtWrp){
                        if(txtWrp.current){
                            txtWrp.current.style.maxHeight = '20vw'
                        }
                    }
                }, 1000)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[0]){
                        document.getElementsByClassName('onepic')[0].style.maxHeight = "1000vh"
                        document.getElementsByClassName('onepic')[0].style.opacity = "1"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[0].style.marginBottom = "3vw"
                        }
                    }
                }, 1500)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[1]){
                        document.getElementsByClassName('onepic')[1].style.maxHeight = "1000vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[1].style.marginBottom = "3vw"
                        }
                    }
                }, 2000)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[2]){
                        document.getElementsByClassName('onepic')[2].style.maxHeight = "1000vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[2].style.marginBottom = "3vw"
                        }
                    }
                }, 2500)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[3]){
                        document.getElementsByClassName('onepic')[3].style.maxHeight = "1000vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[3].style.marginBottom = "3vw"
                        }
                    }
                }, 3000)
                setTimeout(function () {
                    document.querySelectorAll('.page-work-images .onepic').forEach((onepic, i) => {
                        if(i > 3){
                            onepic.style.maxHeight = 'unset'
                            if(!CaseData.posts[0].glued){
                                onepic.style.marginBottom = "3vw"
                            }
                        }
                    })
                }, 3500)
                setTimeout(function () {
                    if(document.querySelector('.page-work-footer-back')){
                        document.querySelector('.page-work-footer-back').style.opacity = "1"
                    }
                    if(h1){
                        if(h1.current){
                            h1.current.style.overflow = "unset"
                        }
                    }
                    setTimeout(function () {
                        if(h1){
                            if(h1.current){
                                h1.current.children[0].style.opacity = "1"
                            }
                        }
                    }, 100)
                }, 4500)
            },descStartTimeOffset)
        }else{
            let w1 = ''
            if(isPad){
                w1 = ''
            }

            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.children[0].style.opacity = '1'
                        h1.current.children[1].style.opacity = '1'
                        if(CaseData.posts[0].logo){
                            h1.current.children[2].style.opacity = '1'
                        }
                    }
                }
                setTimeout(function () {
                    if(txtWrp){
                        if(txtWrp.current){
                            txtWrp.current.style.maxHeight = '100vw'
                            txtWrp.current.style.opacity = '1'
                        }
                    }
                }, 300)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[0]){
                        document.getElementsByClassName('onepic')[0].style.maxHeight = "500vh"
                        document.getElementsByClassName('onepic')[0].style.opacity = "1"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[0].style.marginBottom = "5vw"
                        }
                    }
                }, 600)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[1]){
                        document.getElementsByClassName('onepic')[1].style.maxHeight = "500vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[1].style.marginBottom = "5vw"
                        }
                    }
                }, 1000)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[2]){
                        document.getElementsByClassName('onepic')[2].style.maxHeight = "500vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[2].style.marginBottom = "5vw"
                        }
                    }
                }, 1500)
                setTimeout(function () {
                    if(document.getElementsByClassName('onepic')[3]){
                        document.getElementsByClassName('onepic')[3].style.maxHeight = "500vh"
                        if(!CaseData.posts[0].glued){
                            document.getElementsByClassName('onepic')[3].style.marginBottom = "5vw"
                        }
                    }
                }, 2000)
                setTimeout(function () {
                    document.querySelectorAll('.page-work-images .onepic').forEach((onepic, i) => {
                        if(i > 3){
                            onepic.style.maxHeight = 'unset'
                            if(!CaseData.posts[0].glued){
                                onepic.style.marginBottom = "5vw"
                            }
                        }
                    })
                }, 2500)
                setTimeout(function () {
                    if(document.querySelector('.page-work-footer-back')){
                        document.querySelector('.page-work-footer-back').style.opacity = "1"
                    }
                }, 3500)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, toTopListener, setToTopListener, setMenuPage, CaseLoading, CaseData])

    if (CaseLoading) return <div className='page page-work'></div>
    if (CaseError) return <div className='page page-work'><p>Loading error: {CaseError.message}</p><p>Please reload page.</p></div>
    return (
        <div className='page page-work'>
            <div className="page-work-tit" ref={h1}>
                <Link className="page-work-tit-back" to={`/portfolio${lang !== 'en' ? '/'+lang : ''}`}>«</Link>
                {CaseData.posts[0].logo
                    ?   <img src={CaseData.posts[0].logo.url} alt={CaseData.posts[0].slug}
                             style={CaseData.posts[0].slug === 'auto3n'
                                 ? {mixBlendMode: 'screen', filter: 'invert(1)'}
                                 : {}
                             }/>
                    :   ''
                }
                <h1>{ CaseData.posts[0][`name_${lang}`]
                    ? CaseData.posts[0][`cat_${lang}`] + ' - ' + CaseData.posts[0][`name_${lang}`]
                    : CaseData.posts[0][`cat_${lang}`]
                }</h1>
            </div>
            <div className="page-work-txt" ref={txtWrp}>
                <p>{CaseData.posts[0][`description_${lang}`]}</p>
                <p className="grey">{pg_Portfolio1[6][`${lang}`]}: {CaseData.posts[0].tags.map((tg, q)=>(<span key={q}>{tg[`name_${lang}`]}{q < CaseData.posts[0].tags.length - 1 && ', '}</span>))}</p>
                <p className="grey">{pg_Portfolio1[7][`${lang}`]}: { CaseData.posts[0].logo_style
                    ?   pg_Portfolio1[3][`${lang}`] + ' '
                    :   ''
                }{CaseData.posts[0].hours}</p>
                { CaseData.posts[0].link ? <a href={CaseData.posts[0].url} target="_blank" rel="noreferrer">{CaseData.posts[0].link}</a> : '' }
            </div>
            <div className={`page-work-images ${CaseData.posts[0].glued ? 'glued' : ''}`}>
                {CaseData.posts[0].content.map((pic, i) => {
                        if(pic === 'hr'){
                            return(
                                <hr className="onepic" key={i + "_pic"}/>
                            )
                        }else{
                            if(pic[pic.length -1] === '4'){
                                return(
                                    <video className="onepic" key={i + "_pic"} autoPlay loop muted>
                                        <source src={params.id === 'kitchens' && !isLandscape
                                            ?   '/img/portfolio/' + params.id + '/mob_' + pic
                                            :   '/img/portfolio/' + params.id + '/' + pic
                                        } type="video/mp4" />
                                    </video>
                                )
                            }else{
                                return(
                                    <img className="onepic" key={i + "_pic"} src={params.id === 'kitchens' && !isLandscape
                                        ?   '/img/portfolio/' + params.id + '/mob_' + pic
                                        :   '/img/portfolio/' + params.id + '/' + pic
                                    } alt={pic}/>
                                )
                            }
                        }
                    })
                }
            </div>
            <div className="page-work-footer">
                <Link className="page-work-footer-back" to={`/portfolio${lang !== 'en' ? '/'+lang : ''}`}>
                    <span>«</span> {pg_Portfolio1[9][`${lang}`]}
                </Link>
                <div className="totop" onClick={() => {window.scrollTo(0, 0)}}>▲</div>
                <div className="page-work-footer-empty"> </div>
            </div>
        </div>
    );
};

export default PgWork;