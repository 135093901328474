export const pg_Services2 = [
    {  id:0,
        en: "Services range",
        ru: "Спектр услуг",
    },
    {  id:1,
        en: "Calculate cost",
        ru: "Посчитать цену",
    },
    {  id:2,
        en: "Ask a question",
        ru: "Задать вопрос",
    },
    {  id:3,
        en: "I have a question about",
        ru: "У меня есть вопрос о",
    },
]






















