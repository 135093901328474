import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Files} from "../context/pg_Files";
import {Link} from "react-router-dom";
import {gql, useQuery} from "@apollo/client";

const FilesPg = () => {
    // GraphQL-запрос
    const GET_POSTS = gql`
        query Files {
          files(orderBy: { createdAt: asc }) {
            file_en
            file_ru
            file
            title_en  
            title_ru
            ico
          }
        }
    `;
    const { loading: FileLoading, error: FileError, data: FileData } = useQuery(GET_POSTS)

    // states and vars
    const {lang, isLandscape, appFirstLoad, setAppFirstLoad} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            if(FileLoading){
                return
            }else{
                setPgLoaded(true)
            }
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }

        if(isLandscape){
            setTimeout(function () {
                if(document.querySelector('h1')){
                    document.querySelector('h1').style.opacity = '1'
                }
                setTimeout(function () {
                    if(document.querySelector('.oneFile')){
                        document.querySelectorAll('.oneFile').forEach((onew, w)=>{
                            setTimeout(function (){
                                if(onew.querySelector('h2')){
                                    onew.querySelector('h2').style.maxWidth = '100%'
                                }
                                setTimeout(function (){
                                    if(onew.querySelector('h2 svg')){
                                        onew.querySelectorAll('h2 svg path').forEach((pa)=>{
                                            pa.style.fill = '#fff'
                                        })
                                        onew.querySelector('h2').style.color = '#fff'
                                    }
                                },500)
                                setTimeout(function (){
                                    if(onew.querySelector('p')){
                                        onew.querySelector('p').style.maxHeight = '7vw'
                                        onew.querySelector('p').style.color = '#00abb3'
                                    }
                                },1500)
                            },300 * w)
                        })
                    }
                },300)
            },descStartTimeOffset)
        }else{
            setTimeout(function () {
                if(document.querySelector('h1')){
                    document.querySelector('h1').style.opacity = '1'
                }
                setTimeout(function () {
                    if(document.querySelector('.oneFile')){
                        document.querySelectorAll('.oneFile').forEach((onew, w)=>{
                            setTimeout(function (){
                                if(onew.querySelector('h2')){
                                    onew.querySelector('h2').style.maxHeight = '17vw'
                                    onew.querySelector('h2').style.paddingBottom = '1vw'
                                }
                                setTimeout(function (){
                                    if(onew.querySelector('h2 svg')){
                                        onew.querySelectorAll('h2 svg path').forEach((pa)=>{
                                            pa.style.fill = '#fff'
                                        })
                                        onew.querySelector('h2').style.color = '#fff'
                                    }
                                },500)
                                setTimeout(function (){
                                    if(onew.querySelector('p')){
                                        onew.querySelector('p').style.maxHeight = '65vw'
                                        onew.querySelector('p').style.color = '#00abb3'
                                    }
                                },1500)
                            },300 * w)
                        })
                    }
                },300)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, FileLoading, FileData])

    if (FileLoading) return <div className='page page-files'></div>
    if (FileError) return <div className='page page-files'><p>Loading error: {FileError.message}</p><p>Please reload page.</p></div>
    return (
        <div className='page page-files'>
            <h1>{pg_Files[0][lang]}</h1>
            <div className="page-files-wrp">
                {FileData.files.map((tit, i) => (
                    <div className="oneFile" key={i}>
                        <h2>
                            { tit.ico === "1"
                                ?   <svg viewBox="0 0 240 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M96 2.37305C92.5 4.00018 78.4839 17.0564 67.3775 27.8907C56.2711 38.7251 40.7006 55.5949 30.3775 67.0002L30.3775 86.2588L30.3775 97.9055V153.905V172.905L30.3775 173.177C30.3769 182.966 30.3764 192.777 36.7679 200.904C46.4497 213.216 59.9076 213.079 73.8645 212.936C75.3644 212.921 76.8701 212.905 78.3775 212.905H156.377C162.816 212.905 169.779 212.736 176.688 212.567L176.69 212.567L176.691 212.567C183.491 212.401 190.238 212.236 196.377 212.236C211 212.236 220.377 202.5 219.848 189.905C220.377 180 220.377 167.342 220.377 156.905V111.905V49.9055C220.377 48.7001 220.385 47.44 220.393 46.1419V46.141V46.1408C220.457 35.5621 220.537 22.4632 216.613 15.9055C212.208 8.54275 206.499 1.74058 196.377 1.37305C188.733 1.09547 178.898 1.33507 169.023 1.57564C162.263 1.74032 155.485 1.90545 149.377 1.90545H149.37C134.341 1.90545 113.119 1.90545 96 2.37305ZM119.378 49.4346C102.637 53.2776 93.3872 73.6416 101.731 88.9046C108.037 100.442 121.05 104.716 133.378 101.666C150.348 97.4666 157.548 76.5846 149.015 61.9056C143.149 51.8146 130.684 46.8397 119.378 49.4346ZM78.0079 147.905C82.1503 137.455 92.2328 125.728 103.377 122.543C107.415 121.389 111.197 122.412 115.013 123.443C117.429 124.097 119.86 124.754 122.377 124.864C126.103 125.028 129.881 124.222 133.643 123.419C137.943 122.501 142.222 121.588 146.377 122.133C151.562 122.814 156.569 126.738 160.377 130.089C169.018 137.694 186.024 161.082 166.377 167.252C162.037 168.616 156.761 168.347 151.815 168.095C149.932 167.999 148.096 167.905 146.377 167.905H105.377C103.862 167.905 102.286 167.962 100.678 168.02C94.4352 168.244 87.7305 168.485 82.3929 165.488C75.6823 161.72 75.4182 154.438 78.0079 147.905Z" />
                                    </svg>
                                :   tit.ico === "2"
                                    ?   <svg viewBox="0 0 295 222" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M144.099 0.241504C129.614 1.9944 117.269 9.0828 104.099 14.9522C80.6658 25.3957 57.5341 36.5154 34.0988 46.9522C23.4829 51.6799 2.97348 56.689 0.439076 69.8133C-3.25522 88.9443 17.1795 94.7953 31.0988 100.813C56.3065 111.712 81.1268 123.687 106.099 135.122C121.819 142.32 136.226 150.56 154.099 148.639C166.333 147.325 178.042 139.598 189.099 134.674C213.86 123.647 238.34 111.986 263.099 100.952C273.723 96.2173 292.214 91.2833 293.914 77.8133C296.182 59.8331 278.349 55.1887 265.099 49.1219C239.462 37.3831 213.598 26.0688 188.099 14.0324C174.721 7.7174 159.503 -1.6225 144.099 0.241504Z" />
                                            <path d="M276.098 115.813C271.474 117.145 261.871 120.08 259.126 124.231C256.906 127.588 258.098 133.97 258.098 137.813V167.813C258.098 172.228 257.402 177.762 260.751 181.24C264.889 185.539 272.706 184.092 275.089 178.719C277.241 173.867 276.098 167.003 276.098 161.813V143.813V115.813Z"/>
                                            <path d="M55.0987 132.813C55.0987 152.035 50.0976 178.628 62.628 194.812C71.4087 206.153 86.8807 210.523 100.099 214.389C131.493 223.569 165.871 223.13 197.099 213.53C209.015 209.867 222.512 205.701 230.77 195.809C244.269 179.641 239.099 152.422 239.099 132.813C205.647 142.446 176.534 170.393 140.099 167.723C130.47 167.017 121.746 162.525 113.099 158.674C100.404 153.021 87.7277 147.303 75.0987 141.504C68.5316 138.489 62.0602 134.818 55.0987 132.813Z"/>
                                        </svg>
                                    :   <svg viewBox="0 0 305 194" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M36.2926 2.02822C20.3368 5.43752 13.2936 19.5282 13.2926 34.5105C13.2924 37.9814 11.7861 46.0252 15.6105 47.9071C20.0761 50.1045 28.3912 48.5105 33.2926 48.5105H75.2926H226.293H272.293C276.613 48.5105 286.145 50.2898 289.69 47.4827C292.77 45.0428 291.294 36.9856 291.293 33.5105C291.287 18.0838 282.069 2.95871 265.293 1.60391C249.172 0.301915 232.469 1.51052 216.293 1.51052H119.293H64.2926C55.3265 1.51052 45.0833 0.150016 36.2926 2.02822Z" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.2926 146.511V67.5107H291.293V145.511C291.293 147.418 291.335 149.342 291.378 151.271C291.622 162.352 291.87 173.576 284.08 182.496C273.252 194.892 253.785 193.846 237.032 192.946C232.896 192.724 228.926 192.511 225.293 192.511H77.2926C73.7348 192.511 69.9222 192.703 65.9889 192.901C49.7167 193.722 31.3798 194.646 20.4778 182.496C12.7361 173.868 12.9751 162.91 13.2115 152.072C13.252 150.211 13.2926 148.353 13.2926 146.511ZM59.0347 146.077C54.9181 141.238 56.0622 126.749 62.3117 124.253C67.5335 122.168 77.1369 122.714 80.5501 127.62C84.0303 132.622 83.5558 145.159 77.2376 147.769C73.0541 149.497 62.1972 149.795 59.0347 146.077Z" />
                                        </svg>
                            }
                            {tit[`title_${lang}`]}
                        </h2>
                        <p>
                            {tit.file_ru
                                ?   <Link to={tit.file_ru} target="_blank">{pg_Files[1][lang]} {pg_Files[4][lang]} {pg_Files[2][lang]}</Link>
                                :   ''
                            }
                            {tit.file_en
                                ?   <Link to={tit.file_en} target="_blank">{pg_Files[1][lang]} {pg_Files[4][lang]} {pg_Files[3][lang]}</Link>
                                :   ''
                            }
                            {tit.file
                                ?   <Link to={tit.file} target="_blank">{pg_Files[1][lang]}</Link>
                                :   ''
                            }
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilesPg;