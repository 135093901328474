import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Team1} from "../context/pg_Team";
import {basicText} from "../context/BasicInterfaceText"
import mapAnim from "../img/about/map.gif";
import mapNo from "../img/about/map_none.png";
import icoMail from "../img/about/sr_soc_mail.png";
import icoWa from "../img/about/sr_soc_wa.png";
import icoTg from "../img/about/sr_soc_tg.png";
import sr from "../img/about/blue_line_sr.png";
import ico1 from "../img/about/men_icon_edu.png";
import ico2 from "../img/about/men_icon_data.png";
import ico3 from "../img/about/men_icon_exp.png";
import {gql, useQuery} from "@apollo/client";

const StoryPg = () => {
    // GraphQL-запрос
    const GET_POSTS = gql`
        query Story {
          points {
            city_ru
            city_en
            left
            top
            years
            is_main
            is_mirror
          }
          companies {
            company_en
            company_ru
            end_en
            end_ru
            pic_top
            pic_y
            prof_en
            prof_ru
            text_en
            text_ru
            time_en
            time_ru
          }
        }
    `;
    const { loading: StoryLoading, error: StoryError, data: StoryData } = useQuery(GET_POSTS)

    // states and variables
    const {lang, isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgTeamFirstLoad, setPgTeamFirstLoad} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)
    const [dirDrop, setDirDrop] = useState(false)

    // refs
    const h1 = useRef()
    const h2 = useRef()
    const h22 = useRef()

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            if(StoryLoading){
                return
            }else{
                setPgLoaded(true)
            }
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }
        if(isLandscape){
            let v1 = '3vw 0 0 3.5vw'
            if(isLaptop){
                v1 = '2.5vw 0 0 3.5vw'
            }

            // map animation when scrolling
            if(!pgTeamFirstLoad){
                setPgTeamFirstLoad(true)
                const screenVW = window.innerWidth / 25
                function mapAnimation() {
                    if(document.getElementById('id-map')){
                        let el = document.getElementById('id-map')
                        if(el){
                            let offset =  window.scrollY / screenVW -1
                            el.style.top = offset + 'vw'
                        }
                    }
                }
                document.addEventListener("scroll", mapAnimation)
            }

            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                document.querySelector('.page-team-map-img').src = mapAnim
                setTimeout(function (){
                    if(h2){
                        if(h2.current){
                            h2.current.style.opacity = '1'
                        }
                    }
                },300)
                setTimeout(function (){
                    if(h22){
                        if(h22.current){
                            h22.current.style.opacity = '1'
                        }
                    }
                },900)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.width = '100%'
                    }
                },1600)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.right = '0'
                    }
                    if(document.querySelector('.page-team-map-wrp')){
                        document.querySelector('.page-team-map-wrp').style.opacity = '1'
                    }
                },2100)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.height = '21vw'
                        document.querySelector('.page-team-director-top').style.backgroundColor = '#333'
                        document.querySelector('.page-team-director-top').style.boxShadow = '0 1vw 2vw 0 rgba(0,0,0,.3)'
                    }
                },2700)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top-tab')){
                        document.querySelector('.page-team-director-top-tab').style.width = '6.5vw'
                    }
                },3400)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top')){
                        document.querySelector('.page-team-director-top').style.padding = v1
                        document.querySelector('.page-team-director-top').style.overflow = 'unset'
                    }
                },3700)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top h3')){
                        document.querySelector('.page-team-director-top h3').style.marginLeft = '0'
                        document.querySelector('.page-team-director-top h3').style.width = '50vw'
                    }
                    if(document.querySelector('.page-team-director-top-fon')){
                        document.querySelector('.page-team-director-top-fon').style.display = 'block'
                    }
                },3800)
                setTimeout(function () {
                    if(document.querySelector('.page-team-director-top-subtit')){
                        document.querySelector('.page-team-director-top-subtit').style.marginLeft = '0'
                        document.querySelector('.page-team-director-top-subtit').style.width = '50vw'
                    }
                    if(document.querySelector('.page-team-director-top-fon')){
                        document.querySelector('.page-team-director-top-fon').style.opacity = '1'
                    }
                },4100)
                setTimeout(function () {
                    if(document.querySelector('.dirlink1')){
                        document.querySelector('.dirlink1').style.marginLeft = '0'
                        document.querySelector('.dirlink1').style.maxWidth = '50vw'
                    }
                    if(document.querySelector('.page-team-bottom')){
                        document.querySelector('.page-team-bottom').style.maxHeight = '600vh'
                    }
                },4300)
                setTimeout(function () {
                    if(document.querySelector('.dirlink2')){
                        document.querySelector('.dirlink2').style.marginLeft = '0'
                        document.querySelector('.dirlink2').style.maxWidth = '50vw'
                    }
                },4600)
                setTimeout(function () {
                    if(document.querySelector('.dirlink3')){
                        document.querySelector('.dirlink3').style.marginLeft = '0'
                        document.querySelector('.dirlink3').style.maxWidth = '50vw'
                    }
                    if(document.querySelector('.page-team-bottom')){
                        document.querySelector('.page-team-bottom').style.marginBottom = '4vw'
                    }
                },4900)
            },descStartTimeOffset)
        }else{
            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                setTimeout(function () {
                    if(h2){
                        if(h2.current){
                            h2.current.style.maxHeight = '150vh'
                            h2.current.style.marginBottom = '10vw'
                        }
                    }
                },300)
                setTimeout(function () {
                    if(document.querySelector('.page-team-mobfon')){
                        document.querySelector('.page-team-mobfon').style.opacity = '1'
                    }
                },800)
                setTimeout(function () {
                    if(document.querySelector('.page-team-bottom')){
                        document.querySelector('.page-team-bottom').style.maxHeight = '900vh'
                    }
                },1100)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, pgTeamFirstLoad, setPgTeamFirstLoad, StoryLoading, StoryData])

    if (StoryLoading) return <div className='page page-team'></div>
    if (StoryError) return <div className='page page-team'><p>Loading error: {StoryError.message}</p><p>Please reload page.</p></div>
    return (
        <div className='page page-team'>
            <h1 ref={h1}><span>{pg_Team1[0][lang]}</span> {pg_Team1[1][lang]}</h1>
            {isLandscape ?
                <div className="page-team-top">
                    <h2 ref={h2}>
                        {pg_Team1[2][lang]}
                    </h2>
                    <h2 ref={h22} className="vtoroy">{pg_Team1[29][lang]}{pg_Team1[28][lang]}...</h2>
                    <div id="id-map" className="page-team-map">
                        <img className="page-team-map-img" src={mapNo} alt=""/>
                        <div className="page-team-map-wrp">
                            {StoryData.points.map((city, k) => (
                                <div className={`page-team-map-point ${city.is_main ? "maincity" : ""}`} key={k}
                                     style={{left: `${city.left}vw`, top: `${city.top}vw`}}
                                >
                                    <div className="page-team-map-point-circle"></div>
                                    <div className={`page-team-map-point-details ${city.is_mirror ? "left" : ""}`}>
                                        <p>{city[`city_${lang}`]}</p>
                                        <div className="page-team-map-point-details-link">{city.years}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`page-team-director ${dirDrop ? "page-team-director-active" : ""}`}>
                        <div className="page-team-director-top">
                            <div className="page-team-director-top-tab" onClick={() => setDirDrop(true)} >
                                <img src={sr} alt=""/>
                                <p>{pg_Team1[6][lang]}</p>
                                <p>{pg_Team1[7][lang]}</p>
                            </div>
                            <h3>{pg_Team1[3][lang]}</h3>
                            <p className="page-team-director-top-subtit">{pg_Team1[4][lang]}</p>
                            <a className="dirlink1" href={"mailto:" + basicText[9]['data']}>
                                <img src={icoMail} alt=""/>
                                <span>{basicText[9]['data']}</span>
                            </a>
                            <a className="dirlink2" href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer" >
                                <img src={icoWa} alt=""/>
                                <span>{"+"+basicText[6]['data']}</span>
                            </a>
                            <a className="dirlink3" href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer" >
                                <img src={icoTg} alt=""/>
                                <span>{"@"+basicText[7]['data']}</span>
                            </a>
                            <div className="page-team-director-top-fon"> </div>
                        </div>
                        <div className="page-team-director-drop">
                            <div>
                                <div className="page-team-director-drop-line">
                                    <img src={ico1} alt=""/>
                                    <h5>{pg_Team1[8][lang]}</h5>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong className="point">•</strong>
                                    <p>{pg_Team1[11][lang]}</p>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <span>{pg_Team1[12][lang]} 2004 - 2009</span>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong className="point">•</strong>
                                    <p>{pg_Team1[13][lang]}</p>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <span>{pg_Team1[14][lang]} 1997 - 2002</span>
                                </div>
                                <hr/><hr/>
                                <div className="page-team-director-drop-line">
                                    <img src={ico2} alt=""/>
                                    <h5>{pg_Team1[9][lang]}</h5>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <span>{pg_Team1[15][lang]}</span>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <span>{pg_Team1[16][lang]}</span>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <span>{pg_Team1[17][lang]}</span>
                                </div>
                            </div>
                            <div>
                                <div className="page-team-director-drop-line">
                                    <img src={ico3} alt=""/>
                                    <h5>{pg_Team1[10][lang]}</h5>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>{pg_Team1[19][lang]}</p>
                                        <div className="men-drop-line-years lite year4">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>{pg_Team1[20][lang]}</p>
                                        <div className="men-drop-line-years lite year6">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>{pg_Team1[21][lang]}</p>
                                        <div className="men-drop-line-years lite year7">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>{pg_Team1[22][lang]}</p>
                                        <div className="men-drop-line-years lite year7">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <span>{pg_Team1[24][lang]}</span>
                                        <div className="men-drop-line-years year2">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <span>{pg_Team1[23][lang]}</span>
                                        <div className="men-drop-line-years year2">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>{pg_Team1[18][lang]}</p>
                                        <div className="men-drop-line-years lite year2">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="page-team-director-drop-line">
                                    <strong></strong>
                                    <div className="page-team-director-drop-line-skill">
                                        <p>SEO</p>
                                        <div className="men-drop-line-years lite year3">
                                            <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-team-director-close" onClick={() => setDirDrop(false)}>{pg_Team1[5][lang]}</div>
                    </div>
                </div>
            :
                <div>
                    <h2 ref={h2}>
                        {pg_Team1[2][lang]}
                        <br/>
                        <br/>
                        <span ref={h2}>{pg_Team1[29][lang]}...</span>
                    </h2>
                    <div className="page-team-mobfon"> </div>
                </div>
            }
            <div className="page-team-bottom">
                {!isLandscape ?
                    <div className="men" id="men-id99">
                        <div className="men-top">
                            <img src="/img/about/team_sr.jpg" alt=""/>
                            <div className="men-top-txt">
                                <h4>{pg_Team1[3][lang]}</h4>
                                <p>{pg_Team1[4][lang]}</p>
                            </div>
                        </div>
                        <div className="men-drop">

                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{pg_Team1[19][lang]}</p>
                                <div className="men-drop-line-years year4">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{pg_Team1[20][lang]}</p>
                                <div className="men-drop-line-years year6">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{pg_Team1[21][lang]}</p>
                                <div className="men-drop-line-years year7">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{pg_Team1[22][lang]}</p>
                                <div className="men-drop-line-years year7">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">{pg_Team1[18][lang]}</p>
                                <div className="men-drop-line-years year2">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <div className="men-drop-line">
                                <p className="men-drop-line-name">SEO</p>
                                <div className="men-drop-line-years year3">
                                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                                </div>
                            </div>
                            <p className="edu">{pg_Team1[11][lang]}</p>
                            <span className="edu-span">{pg_Team1[12][lang]} 2004 - 2009</span>
                            <p className="edu">{pg_Team1[13][lang]}</p>
                            <span className="edu-span">{pg_Team1[14][lang]} 1997 - 2002</span>
                            <hr/>
                            <a href={"mailto:" + basicText[9]['data']}>
                                <img src={icoMail} alt=""/>
                                <span>{basicText[9]['data']}</span>
                            </a>
                            <a href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer">
                                <img src={icoWa} alt=""/>
                                <span>{"+"+basicText[6]['data']}</span>
                            </a>
                            <a href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer">
                                <img src={icoTg} alt=""/>
                                <span>{"@"+basicText[7]['data']}</span>
                            </a>
                        </div>
                    </div>
                : ''}
                <h1><span>{pg_Team1[30][lang]}</span> - {pg_Team1[31][lang]}</h1>
                {StoryData.companies.map((com, k) => (
                    <div className="comp" key={k}>
                        <div className={`comp-bg bg${com.pic_top}`}>
                            <img className="comp-pic" src={`/img/about/${com.pic_top}_story.jpg`} alt=""/>
                            {com.pic_y
                                ?   <img className="comp-pic-y" src="/img/about/y_story.jpg" alt=""/>
                                :   ''
                            }
                        </div>
                        <div className={`comp-content ${com.pic_top === '3' || com.pic_top === '2' ? 'padd' : ''}`}>
                            <p className={`comp-name ${com.pic_top === '3' || com.pic_top === '4' ? 'now' : ''}`}>{com[`company_${lang}`]}</p>
                            <p className={`comp-prof ${com.pic_top === '3' || com.pic_top === '4' ? 'now' : ''}`}>{com[`prof_${lang}`]}</p>
                            {isLandscape
                                ?   ''
                                :   <div className="comp-dates">
                                        <p className={`comp-time ${com.pic_top === '3' || com.pic_top === '4' ? 'now' : ''}`}>{com[`time_${lang}`]} <span>({pg_Team1[32][lang]} {com[`end_${lang}`]})</span></p>
                                    </div>
                            }
                            <p className="comp-text">{com[`text_${lang}`]}</p>
                        </div>
                        {isLandscape
                            ?   <div className="comp-dates">
                                    <p className={`comp-time ${com.pic_top === '3' || com.pic_top === '4' ? 'now' : ''}`}>{com[`time_${lang}`]}</p>
                                    <p className={`comp-end ${com.pic_top === '3' || com.pic_top === '4' ? 'now' : ''}`}>{pg_Team1[32][lang]} {com[`end_${lang}`]}</p>
                                </div>
                            :   ''
                        }
                    </div>
                ))}
                <div className="page-team-bottom-line"> </div>
            </div>
        </div>
    );
};

export default StoryPg;