export const pg_Team1 = [
    {   id: 0,
        en: "Career",
        ru: "История",
    },
    {   id: 1,
        en: "history in companies",
        ru: "карьеры в компаниях",
    },
    {   id: 2,
        en: "My first passion for digital graphics began at the age of 6 - I was drawing frame-by-frame animations in CPEN on an IBM386. In high school, I was into creating 3D maps for shooters like Unreal. And when I entered university to become a programmer and the Internet burst into my consciousness, I directed my creative energy to creating animated sites using ActionScript and Macromedia Flash.",
        ru: "Первые увлечения цифровой графикой начались в 6 лет - тогда я рисовал покадровые анимации в CPEN на IBM386. В старших классах увлёкался созданием 3D карт для шутеров вроде Unreal. А когда поступил в университет на программиста и в моё сознание ворвался интернет, то направил свою творческую энергию на создание анимационных сайтов средствами ActionScript и Macromedia Flash.",
    },


    {   id: 3,
        en: "Samborsky Roman",
        ru: "Самборский Роман",
    },
    {   id: 4,
        en: "FULL-STACK WEB DEVELOPER",
        ru: "ФУЛСТЭК ВЕБ РАЗРАБОТЧИК",
    },
    {   id: 5,
        en: "Collapse details",
        ru: "Свернуть подробности",
    },
    {   id: 6,
        en: "click for",
        ru: "жми, узнай",
    },
    {   id: 7,
        en: "details",
        ru: "детали",
    },


    {   id: 8,
        en: "Education",
        ru: "Образование",
    },
    {   id: 9,
        en: "Details",
        ru: "Детали",
    },
    {   id: 10,
        en: "Experience in years",
        ru: "Опыт в годах",
    },


    {   id: 11,
        en: "Novosibirsk State University of Civil Engineering and Architecture",
        ru: "Новосибирский Государственный Архитектурно-Строительный Университет",
    },
    {   id: 12,
        en: "Information systems and technology department",
        ru: "Фак-т: «Информационные Системы и Технологии»",
    },
    {   id: 13,
        en: "Art school «North Spring»",
        ru: "Художественная школа «Весна»",
    },
    {   id: 14,
        en: "Diploma",
        ru: "Диплом",
    },
    {   id: 15,
        en: "Current address: Turkey, Alanya",
        ru: "Текущий адрес: Турция, Аланья",
    },
    {   id: 16,
        en: "English level: Intermediate (B1)",
        ru: "Уровень английского: Intermediate (B1)",
    },
    {   id: 17,
        en: "Date of Birth: June 23, 1987",
        ru: "Дата рождения: 23.06.87",
    },


    {   id: 18,
        en: "Team lead (CRM, GIT)",
        ru: "Тимлид (CRM, GIT)",
    },
    {   id: 19,
        en: "Backend (PHP, NodeJS)",
        ru: "Бэкенд (PHP, NodeJS)",
    },
    {   id: 20,
        en: "Frontend (React, JS)",
        ru: "Фронтенд (React, JS)",
    },
    {   id: 21,
        en: "Layout (HTML, CSS)",
        ru: "Верстка (HTML, CSS)",
    },
    {   id: 22,
        en: "Design (UX, UI)",
        ru: "Дизайн (UX, UI)",
    },
    {   id: 23,
        en: "Branding",
        ru: "Брендинг",
    },
    {   id: 24,
        en: "Polygraphy",
        ru: "Полиграфия",
    },
    {   id: 25,
        en: "Raster illustrations",
        ru: "Иллюстрации (растра)",
    },
    {   id: 26,
        en: "Vector illustrations",
        ru: "Иллюстрации (вектор)",
    },
    {   id: 27,
        en: "3D illustrations",
        ru: "Иллюстрации (3D)",
    },

    {   id: 28,
        en: ", and also marked on the map the experience gained from living in different parts of the world",
        ru: ", а также отметил на карте полученный опыт жизни в разных точках мира",
    },
    {   id: 29,
        en: "After graduating from the university, I got a job at a web studio as a designer and in the following years I mastered layout, then frontend, and then backend. After 14 years of work, I immersed myself in many other aspects of the web industry. I have described the skills acquired during my career in detail by year below",
        ru: "После окончания ВУЗа устроился в web-студию дизайнером и в последующие годы освоил вёрстку, затем фронтенд, а после и бэкенд. За прошедшие 14 лет работы погружался и во многие другие грани web-индустрии. Навыки приобретённые во время карьеры подробно расписал по годам ниже",
    },

    {   id: 30,
        en: "14 years",
        ru: "14 лет",
    },
    {   id: 31,
        en: "experience in web development",
        ru: "опыт в web разработке",
    },
    {   id: 32,
        en: "until",
        ru: "по",
    },
]